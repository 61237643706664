import GoogleMapReact from 'google-map-react'
import React from 'react'
import Icon from '../assets/Icon'
import { colors, globals, s, sSection } from '../style'

const mapConfig = {
  bootstrapURLKeys: {
    key: process.env.GATSBY_GOOGLE_API,
    libraries: ['places'],
  },
  defaultCenter: {
    lat: 52.18633013811715,
    lng: 20.956229605798885,
  },
  defaultZoom: 6,
  yesIWantToUseGoogleMapApiInternals: true,
  options: {
    panControl: false,
    mapTypeControl: false,
    styles: [
      {
        stylers: [
          { saturation: -100 },
          { gamma: 0.8 },
          { lightness: 4 },
          { visibility: 'on' },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            color: '#F5F5F5',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EBEBEB',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EBEBEB',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: colors.brand,
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: colors.brandLighter,
          },
          {
            lightness: '0',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            color: colors.brandLighter,
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: colors.brandLighter,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: colors.brandLighter,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: colors.brandLighter,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
          {
            color: colors.brandLighter,
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: colors.brandLighter,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  },
}

const locations = [
  { city: 'Gdynia', lat: 54.52289804355159, lon: 18.542474090141553 },
  { city: 'Warszawa', lat: 52.18633013811715, lon: 20.956229605798885 },
  { city: 'Kraków', lat: 50.0092544, lon: 19.9327325 },
  { city: 'Rzeszów', lat: 50.0185334, lon: 21.9645746 },
  { city: 'Katowice', lat: 50.2532259, lon: 19.0602804 },
  { city: 'Słubice', lat: 52.35467435826884, lon: 14.562561362922494 },
  { city: 'Wrocław', lat: 51.0952731, lon: 16.9753484 },
]

const Map = () => {
  return (
    <div css={sSection}>
      <div css={sMap}>
        <GoogleMapReact
          {...mapConfig}
          async={true}
          bootstrapURLKeys={mapConfig.bootstrapURLKeys}
        >
          {locations.map(({ lat, lon }, id) => (
            <Icon key={id} lat={lat} lng={lon} />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  )
}

const sMap = {
  borderRadius: '4rem',
  overflow: 'hidden',
  position: 'relative',
  top: 0,
  left: 0,
  height: 'calc(900vw / 16)',
  maxHeight: `calc(${globals.maxWidth}px * 0.5625)`,
  [s.sm_down]: {
    borderRadius: '2rem',
    height: 'calc(100vw - 4rem)',
  },
}

export default Map
