export const seo = {
  url: 'firma/osiagniecia',
  title: {
    pl: 'Osiągnięcia',
    en: 'Achievements',
  },
  desc: {
    pl: `Osiągnięte sukcesy i zdobyte nagrody są dla nas nie tylko źródłem dumy, ale także mobilizują nas do ciągłego rozwijania naszej oferty i świadczenia usług na najwyższym poziomie.`,
    en: `The successes achieved and the awards won are not only a source of pride for us, but also motivate us to constantly develop our offer and provide services at the highest level.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'osiągnięcia', 'nagrody tsl'],
}

export const intro = {
  title: {
    pl: 'Osiągnięcia',
    en: 'Achievements',
  },
  desc: {
    pl: `Jesteśmy dumni z naszej historii i osiągniętych w tym czasie sukcesów!`,
    en: `We are proud of our history and the successes achieved during that time!`,
  },
}

export const main = {
  title: {
    pl: 'Osiągnięcia oraz nagrody<br/>Omida Sea And Air S.A.',
    en: 'Achievements & awards<br/>Omida Sea And Air S.A.',
  },
  body: {
    pl: `Osiągnięte sukcesy i zdobyte nagrody są dla nas nie tylko źródłem dumy, ale także mobilizują nas do ciągłego rozwijania naszej oferty i świadczenia usług na najwyższym poziomie.`,
    en: `The successes achieved and the awards won are not only a source of pride for us, but also motivate us to constantly develop our offer and provide services at the highest level.`,
  },
  sections: [
    {
      title: {
        pl: 'Diament Miesięcznika Forbes',
        en: 'Forbes Diamond',
      },
      desc: {
        pl: 'Omida Group posiada prestiżowe nagrody miesięcznika Forbes – Diament Forbesa. W 2023 roku Grupa Omida po raz kolejny znalazła się na liście Diamentów Forbes.',
        en: 'Omida Group holds the prestigious Forbes Diamond awards. They are awarded to the most dynamically developing companies in the country. In 2019, we were ranked 2nd in the category of companies with revenues above PLN 250 million in the Pomeranian region. In 2017, we took first place in the Pomeranian and national ranking of Forbes Diamonds in the category of companies with a turnover of PLN 50 to 250 million.',
      },
    },
    {
      title: {
        pl: 'Nagroda Gryf Gospodarczy 2022',
        en: 'Forbes Diamond',
      },
      desc: {
        pl: 'Omida Group została laureatem „Gryfa Gospodarczego” w 2022 r. w kategorii lidera zielonej transformacji w ramach projektu Transport Intermodalny.',
        en: 'Omida Group holds the prestigious Forbes Diamond awards. They are awarded to the most dynamically developing companies in the country. In 2019, we were ranked 2nd in the category of companies with revenues above PLN 250 million in the Pomeranian region. In 2017, we took first place in the Pomeranian and national ranking of Forbes Diamonds in the category of companies with a turnover of PLN 50 to 250 million.',
      },
    },
    {
      title: {
        pl: 'Diament Miesięcznika Forbes',
        en: 'Forbes Diamond',
      },
      desc: {
        pl: 'Omida Group posiada prestiżowe nagrody miesięcznika Forbes – Diament Forbesa. Przyznawane są one najdynamiczniej rozwijającym się firmom w kraju. W 2019 roku zajęliśmy 2. miejsce w kategorii firm o poziomie przychodów powyżej 250 mln PLN w województwie pomorskim. W 2017 roku zajęliśmy pierwsze miejsce w rankingu pomorskich i ogólnopolskich Diamentów Forbesa w kategorii firm o obrotach od 50 do 250 mln PLN.',
        en: 'Omida Group holds the prestigious Forbes Diamond awards. They are awarded to the most dynamically developing companies in the country. In 2019, we were ranked 2nd in the category of companies with revenues above PLN 250 million in the Pomeranian region. In 2017, we took first place in the Pomeranian and national ranking of Forbes Diamonds in the category of companies with a turnover of PLN 50 to 250 million.',
      },
    },
    // {
    //   title: {
    //     pl: 'Solidny Pracodawca Roku',
    //     en: 'Reliable Employer of the Year',
    //   },
    //   desc: {
    //     pl: 'Solidny Pracodawca Roku to nagroda przyznawana rzetelnym i przyjaznym pracownikom firmom. Nagroda przyznawana jest najlepszym pracodawcom w Polsce, szczególnie tym promującym ciekawe rozwiązania HR oraz świadczenia dla pracowników. Omida Group została wyróżniona nagrodą Solidnego Pracodawcy w 2017 roku.',
    //     en: 'Reliable Employer of the Year is an award given to reliable and employee-friendly companies. The award is given to the best employers in Poland, especially those promoting interesting HR solutions and employee benefits. Omida Group was awarded the Reliable Employer of the Year award in 2017.',
    //   },
    // },
    {
      title: {
        pl: 'Gazele Biznesu',
        en: 'The Gazelles of Business',
      },
      desc: {
        pl: 'Gazele Biznesu to nagrody wręczane przez Puls Biznesu od 2000 roku. Przyznawane są najdynamiczniej rozwijającym się małym i średnim przedsiębiorstwom. Omida Group została nagrodzona kolejno w 2014, 2015, 2016 i 2018 roku.',
        en: 'The Gazelles of Business awards have been bestowed by Puls Biznesu since 2000. They are awarded to the most dynamically developing small and medium enterprises. Omida Group was awarded in 2014, 2015, 2016 and 2018.',
      },
    },
    {
      title: {
        pl: 'Gryf Gospodarczy ',
        en: 'Economic Griffin',
      },
      desc: {
        pl: 'Gryf Gospodarczy jedna z najbardziej prestiżowych nagród gospodarczych przyznawana przez władze i organizacje z województwa pomorskiego. Omida Group została laureatem „Gryfa Gospodarczego” w 2015 r. w kategorii średnich przedsiębiorstw zatrudniających od 50 do 249 pracowników.',
        en: 'Economic Griffin is one of the most prestigious economic awards granted by authorities and organizations of the Pomeranian region. Omida Group was awarded the "Economic Griffin" in 2015 in the category of medium-sized companies with 50 to 249 employees.',
      },
    }
  ],
}
