import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Main from '../components/Main'
import Button from '../components/Button'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import { globals, s } from '../style'
import BannerRecruitment from '../sections/banners/BannerRecruitment'
import SocialsSections from '../sections/jobs/SocialsSections'
import VideosSection from '../sections/jobs/VideosSection'
import BenefitsSection from '../sections/jobs/BenefitsSection'
import GptwSection from '../sections/jobs/GptwSection'
import GallerySection from '../sections/jobs/gallerySection'
import AchievementsSection from '../sections/jobs/AchievementsSection'

const content = {
  subtitle: {
    pl: 'Sea & Air Kariera',
    en: 'Sea & Air Career',
  },
}

export default function JobTemplate({ data }) {
  const { lang } = useLangContext()
  const { setFormType } = useFormContext()

  const jobs =
    data.jobs.nodes.find((node) => node.lang.slug === lang) ||
    data.jobs.nodes.find((node) => node.lang.slug === 'pl')

  const { slug, title, seo_title, seo_desc, image, body, city } = jobs

  const button = {
    text: {
      pl: 'Czytaj',
      en: 'Read',
    },
    action: 'SCROLL',
  }

  const buttonBack = {
    text: {
      pl: 'Powrót do ofert pracy',
      en: 'Back to job offers ',
    },
    link: '/kariera/#oferty-pracy',
  }

  useEffect(() => {
    setFormType('MESSAGE')
  }, [])

  return (
    <Layout>
      <Seo
        title={seo_title}
        desc={seo_desc}
        imageUrl={image.file.url}
        url={'kariera/praca/' + slug}
      />
      <Intro
        title={title}
        desc={seo_desc}
        button={button}
        image={image}
        position='center'
      />
      <Main
        h={1}
        title={title + ' ' + city}
        subtitle={content.subtitle[lang]}
        article={body.childMarkdownRemark.html}
        // maxWidth={globals.blogMaxWidth}
        extraCssTitle={{
          [s.lg]: {
            maxWidth: '100%',
          },
        }}
      >
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
      <GptwSection />
      <VideosSection />
      <BenefitsSection />
      <AchievementsSection />
      <GallerySection />
      <Main>
        <BannerRecruitment />
      </Main>
      <SocialsSections />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    jobs: allContentfulJob(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        slug
        title
        seo_title
        seo_desc
        city
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
          file {
            url
          }
        }
      }
    }
  }
`
