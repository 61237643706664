import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'

const GptwSection = () => {
  const { lang } = useLangContext()

  const content = {
    title: {
      pl: 'Dlaczego właśnie my?',
      en: ''
    },
    desc: {
      pl: `<p>Jeśli interesuje Cię praca w <strong>stabilnej</strong>, ale <strong>dynamicznie</strong> rozwijającej się firmie o <strong>uznanej</strong> marce na rynku, jeśli planujesz zdobyć praktyczną wiedzę i <strong>doświadczenie</strong> pracując w <strong>profesjonalnym</strong> i <strong>życzliwym</strong> zespole ludzi, jeśli masz poczucie humoru i dystans do samego siebie to Omida Sea and Air S.A. <strong>jest idealnym miejsce dla Ciebie!</strong></p>`,
      en: ''
    },
  }

  const query = useStaticQuery(graphql`
    {
      gptw23: file(absolutePath: { regex: "/images/company/why_we.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  return (
    <Main>
      <ImageSection
        h={2}
        title={content.title[lang]}
        desc={content.desc[lang]}
        image={query.gptw23.childImageSharp}
        imageLeft
      />
  </Main>
  )
}


export default GptwSection